const SPACE_DATA = {
		"u6981089":  [{space_js: "//hhaabb.hanganan.com/production/tonw/qx/u_qw.js", space_src: "baidujs", space_type: "inters", space_id: "u6981089"}],
		"u6981091":  [{space_js: "//hhaabb.hanganan.com/common/m/hg/source/pjqn/resource/pq.js", space_src: "baidujs", space_type: "native", space_id: "u6981091"}],
		"u6981090":  [{space_js: "//hhaabb.hanganan.com/production/l/openjs/gf/common/o_i/source/pmom.js", space_src: "baidujs", space_type: "native", space_id: "u6981090"}],
		"u6981092":  [{space_js: "//hhaabb.hanganan.com/site/n/production/ihq_k_r_oqx.js", space_src: "baidujs", space_type: "native", space_id: "u6981092"}],
		"u6981093":  [{space_js: "//hhaabb.hanganan.com/source/oj-i/static/r/source/l-sprb.js", space_src: "baidujs", space_type: "native", space_id: "u6981093"}],
		"u6982998":  [{space_js: "//hhaabb.hanganan.com/common/ez_y/hboh_hb.js", space_src: "baidujs", space_type: "inters", space_id: "u6982998"}],
		"u6983000":  [{space_js: "//hhaabb.hanganan.com/source/e_z/resource/y/static/h/common/brfff.js", space_src: "baidujs", space_type: "native", space_id: "u6983000"}],
		"u6982999":  [{space_js: "//hhaabb.hanganan.com/production/fa_zi/source/c/production/pi/common/ii.js", space_src: "baidujs", space_type: "native", space_id: "u6982999"}],
		"u6983001":  [{space_js: "//hhaabb.hanganan.com/source/f_a_z/ics/common/ggj.js", space_src: "baidujs", space_type: "native", space_id: "u6983001"}],
		"u6983002":  [{space_js: "//hhaabb.hanganan.com/site/g/openjs/ba_j_dthh/q.js", space_src: "baidujs", space_type: "native", space_id: "u6983002"}],
		"u6989611":  [{space_js: "//hhaabb.hanganan.com/source/s/common/n-m-vpvmw/resource/w.js", space_src: "baidujs", space_type: "inters", space_id: "u6989611"}],
		"u6989612":  [{space_js: "//hhaabb.hanganan.com/production/t/source/on_wq/w/nxd.js", space_src: "baidujs", space_type: "native", space_id: "u6989612"}],
		"u6989613":  [{space_js: "//hhaabb.hanganan.com/common/up/oxr_x/production/o_yh.js", space_src: "baidujs", space_type: "native", space_id: "u6989613"}],
		"u6989614":  [{space_js: "//hhaabb.hanganan.com/common/v/production/q_p_ys/ypzm.js", space_src: "baidujs", space_type: "native", space_id: "u6989614"}],
		"u6989615":  [{space_js: "//hhaabb.hanganan.com/production/w/openjs/rq_ztz/qa_o.js", space_src: "baidujs", space_type: "native", space_id: "u6989615"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6981089"],
	Home_native:  SPACE_DATA["u6981091"],
	Home_banner:  SPACE_DATA["u6981090"],
	Detail_inter:  SPACE_DATA["u6981089"],
	Detail_native:  SPACE_DATA["u6981091"],
	Detail_banner:  SPACE_DATA["u6981090"],
	List_native:  SPACE_DATA["u6981091"],
	List_banner:  SPACE_DATA["u6981090"],
	Result_inter:  SPACE_DATA["u6981089"],
	Result_banner:  SPACE_DATA["u6981090"],
	Result_native:  SPACE_DATA["u6981091"],
	Result_native_center1:  SPACE_DATA["u6981092"],
	Result_native_center2:  SPACE_DATA["u6981093"],
}
export const ALL_SPACE_MAP = {
    HNW_HABZ_VO_1 : {
		Home_inter:  SPACE_DATA["u6981089"],
		Home_native:  SPACE_DATA["u6981091"],
		Home_banner:  SPACE_DATA["u6981090"],
		Detail_inter:  SPACE_DATA["u6981089"],
		Detail_native:  SPACE_DATA["u6981091"],
		Detail_banner:  SPACE_DATA["u6981090"],
		List_native:  SPACE_DATA["u6981091"],
		List_banner:  SPACE_DATA["u6981090"],
		Result_inter:  SPACE_DATA["u6981089"],
		Result_banner:  SPACE_DATA["u6981090"],
		Result_native:  SPACE_DATA["u6981091"],
		Result_native_center1:  SPACE_DATA["u6981092"],
		Result_native_center2:  SPACE_DATA["u6981093"],
    },
    HNW_HABZ_VO_2 : {
		Home_inter:  SPACE_DATA["u6982998"],
		Home_native:  SPACE_DATA["u6983000"],
		Home_banner:  SPACE_DATA["u6982999"],
		Detail_inter:  SPACE_DATA["u6982998"],
		Detail_native:  SPACE_DATA["u6983000"],
		Detail_banner:  SPACE_DATA["u6982999"],
		List_native:  SPACE_DATA["u6983000"],
		List_banner:  SPACE_DATA["u6982999"],
		Result_inter:  SPACE_DATA["u6982998"],
		Result_banner:  SPACE_DATA["u6982999"],
		Result_native:  SPACE_DATA["u6983000"],
		Result_native_center1:  SPACE_DATA["u6983001"],
		Result_native_center2:  SPACE_DATA["u6983002"],
    },
    HNW_HABZ_VO_3 : {
		Home_inter:  SPACE_DATA["u6989611"],
		Home_banner:  SPACE_DATA["u6989612"],
		Home_native:  SPACE_DATA["u6989613"],
		Detail_inter:  SPACE_DATA["u6989611"],
		Detail_banner:  SPACE_DATA["u6989612"],
		Detail_native:  SPACE_DATA["u6989613"],
		List_banner:  SPACE_DATA["u6989612"],
		List_native:  SPACE_DATA["u6989613"],
		Result_inter:  SPACE_DATA["u6989611"],
		Result_banner:  SPACE_DATA["u6989612"],
		Result_native:  SPACE_DATA["u6989613"],
		Result_native_center1:  SPACE_DATA["u6989614"],
		Result_native_center2:  SPACE_DATA["u6989615"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6981089"],
		Home_native:  SPACE_DATA["u6981091"],
		Home_banner:  SPACE_DATA["u6981090"],
		Detail_inter:  SPACE_DATA["u6981089"],
		Detail_native:  SPACE_DATA["u6981091"],
		Detail_banner:  SPACE_DATA["u6981090"],
		List_native:  SPACE_DATA["u6981091"],
		List_banner:  SPACE_DATA["u6981090"],
		Result_inter:  SPACE_DATA["u6981089"],
		Result_banner:  SPACE_DATA["u6981090"],
		Result_native:  SPACE_DATA["u6981091"],
		Result_native_center1:  SPACE_DATA["u6981092"],
		Result_native_center2:  SPACE_DATA["u6981093"],
    },
};
